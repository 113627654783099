<template>
    <div id="mint">
        <div class="mint-content1">
            <div class="banner-img" v-rellax="rellax"></div>
            <div class="mint-content1-page">
                <div class="mint-content1-left" v-rellax="rellax1">
                    <div class="mint-content1-left-title">Mint a block history</div>
                    <div class="mint-content1-left-text1">The World's First NFT Platform Dedicated</div>
                    <div class="mint-content1-left-text2">To Minting Great Moments In History.</div>
                    <div class="mint-content1-left-button">
                        <div class="mint-content1-left-mint" @click="goView('selectTheNft')">Mint</div>
                        <div class="mint-content1-left-market" @click="goView('market')">Market</div>
                    </div>
                </div>
                <div class="mint-content1-right" v-rellax="rellax1">
                    <div class="swiper-wrapper">
                        <swiper :options="swiperOption" ref="mySwiper">
                            <swiper-slide v-for="(item,index) of swiperList" :key="index" class="swiper-item">
                                <div class="mint-content1-right-img ani" swiper-animate-effect="zoomIn" swiper-animate-duration="2s"><img :src="item.img"></div>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-pagination" slot="pagination">
                        </div>
                        <div class="swiper-button-prev hvr-backward"></div>
                        <div class="swiper-button-next hvr-forward"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-rellax="rellax2" class="rellax">
            <div class="mint-content2">
                <div class="mint-content2-list-flex">
                    <div class="mint-content2-textBox" v-for="(item,index) in introduceList" :key="index">
                        <div class="mint-content2-list">
                            <img :src="item.images" class="home-title-img">
                            <div>
                                <!-- <p :class="isHeight==true?'mint-content2-line1 mint-content2-line1-1':'mint-content2-line4 mint-content2-line1-2'">{{ item.line1 }}</p>
          <p :class="isHeight==true?'mint-content2-line1':'mint-content2-line4'">{{ item.line2 }}</p>
          <p :class="isHeight1==true?'mint-content2-line2':'mint-content2-line3'">{{ item.line3 }}</p> -->
                                <p v-show="isHeight==true" class="mint-content2-line1">{{ item.line1 }}</p>
                                <p :class="isHeight==true?'mint-content2-line1':'mint-content2-line4'">{{ item.line2 }}</p>
                                <p :class="isHeight1==true?'mint-content2-line2':'mint-content2-line3'">{{ item.line3 }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="history" v-if="allHistoryList.length!==0">
                <div class="history-title">Latest block history</div>
                <div :class="allHistoryList.length<=3?'historyList1':'historyList'">
                    <div :class="allHistoryList.length<=3?'historyLi1':'historyLi'" v-for="(item,index) in allHistoryList" :key="index">
                        <div @click="goHistory(item)">
                            <div class="history-img"><img :src="item.awsurl?item.awsurl:item.ipfsurl"></div>
                            <div class="history-information-box">
                                <div class="history-information1">
                                    <div class="history-name">{{item.title}}</div>
                                    <div class="history-price">
                                        <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                                        <div>{{item.price}}</div>
                                    </div>
                                </div>
                                <div class="history-information2">
                                    <div class="history-id">#{{total - index}}</div>
                                    <div class="history-time">{{item.timeago}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="history-actionBar" v-show="item.authentication == false">
                            <div class="history-button"><span v-if="item.buyState===true&&item.state!=null&&item.state!=''" @click="bugNow(item)">Buy now</span></div> <!--  :class="{'optionsState': item.buyState===false}" -->
                            <div class="history-button"><span v-if="item.followState===true" @click="follow(item)">Follow</span></div> <!--  :class="{'optionsState': item.followState===false}" -->
                        </div>
                        <div v-show="item.authentication == true">
                            <div class="history-actionBar" v-if="item.state === null || item.state === ''">
                                <div class="history-button" @click="goTransaction(item,'sell')">Sell</div>
                                <div class="history-button" @click="goTransaction(item,'Transfer')">Transfer</div>
                            </div>
                            <div class="history-actionBar" v-else-if="item.state === 'sale'">
                                <div class="history-button" @click="cancelSale1(item)">Cancel Listing</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lookMore" @click="moreHistory(2)">Go to Market</div>
            </div>
            <div class="article record article1">
                <div class="article-title">Record your First History</div>
                <div class="article-list">
                    <div class="article-li" v-for="(item,index) in articleList" :key="index">
                        <div class="article-list-img"><img :class="'img'+index" :src="item.img"></div>
                        <div class="article-list-name">{{item.name}}</div>
                        <div class="article-list-text">{{item.text}}</div>
                    </div>
                </div>
            </div>
            <div class="history" v-if="seriesList1.length!==0">
                <div class="history-title">Latest Series NFT</div>
                <div :class="seriesList1.length<=3?'historyList1':'historyList'">
                    <div :class="seriesList1.length<=3?'historyLi1':'historyLi'" v-for="(item,index) in seriesList1" :key="index">
                        <div @click="goHistory1(item)">
                            <div class="history-img"><img :src="item.awsurl?item.awsurl:item.ipfsurl"></div>
                            <div class="history-information-box">
                                <div class="history-information1">
                                    <div class="history-name">{{item.title}}</div>
                                    <div class="history-price">
                                        <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                                        <div>{{item.price}}</div>
                                    </div>
                                </div>
                                <div class="history-information2">
                                    <div class="history-id">#{{total - index}}</div>
                                    <div class="history-time">{{item.timeago}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="history-actionBar" v-show="item.authentication == false">
                            <div class="history-button"><span v-if="item.buyState===true&&item.state!=null&&item.state!=''" @click="bugNow1(item)">Buy now</span></div>
                            <div class="history-button"></div> <!--  :class="{'optionsState': item.followState===false}" -->
                        </div>
                        <div v-show="item.authentication == true">
                            <div class="history-actionBar" v-if="item.state === null || item.state === ''">
                                <div class="history-button" @click="goTransaction(item,'sell')">Sell</div>
                                <div class="history-button" @click="goTransaction(item,'Transfer')">Transfer</div>
                            </div>
                            <div class="history-actionBar" v-else-if="item.state === 'sale'">
                                <div class="history-button" @click="cancelSaleNew(item)">Cancel Listing</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lookMore" @click="moreHistory(1)">Go to Market</div>
            </div>
            <div class="background-content">
                <div class="background-box">
                    <div class="background-title">Main Function</div>
                    <div class="background-list slide-scroll">
                        <div class="slide-box">
                            <template v-for="item in 3">
                                <div class="swiper-item-box">
                                    <div class="background-img-box">
                                        <div class="background-imgBg">
                                            <img src="../../assets/images/mainFunction-icon1.png" class="background-img">
                                        </div>
                                        <div class="background-img-title">Minting Historic Events</div>
                                        <div class="background-img-info">Anyone can record what happened on theblockchain and mint it into a historic event NFT.</div>
                                    </div>
                                </div>
                                <div class="swiper-item-box">
                                    <div class="background-img-box">
                                        <div class="background-imgBg">
                                            <img src="../../assets/images/mainFunction-icon3.png" class="background-img">
                                        </div>
                                        <div class="background-img-title">NFT Trading</div>
                                        <div class="background-img-info">All historic NFTs can be traded on the platform.</div>
                                    </div>
                                </div>
                                <div class="swiper-item-box">
                                    <div class="background-img-box">
                                        <div class="background-imgBg">
                                            <img src="../../assets/images/mainFunction-icon5.png" class="background-img">
                                        </div>
                                        <div class="background-img-title">Browser</div>
                                        <div class="background-img-info">A variety of tools to facilitate user query and browsing of historical events.</div>
                                    </div>
                                </div>
                                <div class="swiper-item-box">
                                    <div class="background-img-box">
                                        <div class="background-imgBg">
                                            <img src="../../assets/images/mainFunction-icon2.png" class="background-img">
                                        </div>
                                        <div class="background-img-title">Follow Historic Events</div>
                                        <div class="background-img-info">Within 10-30 minutes of the historic event NFT creation, a limited 100 users can follow the historic event and mint a copy of the NFT.</div>
                                    </div>
                                </div>
                                <div class="swiper-item-box">
                                    <div class="background-img-box">
                                        <div class="background-imgBg">
                                            <img src="../../assets/images/mainFunction-icon4.png" class="background-img">
                                        </div>
                                        <div class="background-img-title">Community Interaction</div>
                                        <div class="background-img-info">Weekly, monthly, and annual historic event selections, person of the year events, album creation, and major historic event album collections.</div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="series" v-if="seriesList.length>0">
                <div class="series-title">Lastest series</div>
                <div :class="seriesList.length<=3?'series-list1':'series-list'">
                    <div :class="seriesList.length<=3?'series-li1':'series-li'" v-for="(item,index) in seriesList" :key="index" @click="goMarket(item.seriesid,item.admin,item.tokenaddress)">
                        <div class="series-list-img-box">
                            <div class="series-list-img1"><img :src="`https://ipfs.io/ipfs/${item.serieslogo}`"></div>
                            <div class="series-list-img-box1">
                                <img :src="`https://ipfs.io/ipfs/${item.seriesbanner}`" class="series-list-img2">
                            </div>
                        </div>
                        <div class="series-list-text-box">
                            <div class="series-list-name">{{item.seriesname}}</div>
                            <div class="series-list-name1"><span class="by-name">by</span><span class="name-info">{{seriesAdmin}}</span></div>
                            <div class="series-list-text">{{item.description}}</div>
                        </div>
                    </div>
                </div>
                <div class="lookMore" @click="moreHistory(0)">Go to Market</div>
            </div>
            <div class="backers" style="display:none">
                <div class="backers-title">Backers</div>
                <div class="backers-list">
                    <a target="_blank" href="http://capital-chain.com/">
                        <img class="img1" src="@/assets/images/capitalchain.svg" alt="">
                        <img class="img1 m" src="@/assets/images/m-capitalchain.png" alt="">
                    </a>
                    <div class="line"></div>
                    <a target="_blank" href="http://consensus-lab.com/">
                        <img class="img2" src="@/assets/images/consensuslab.svg" alt="">
                        <img class="img2 m" src="@/assets/images/m-consensuslab.png" alt="">
                    </a>
                </div>
            </div>
            <div class="article article2">
                <div class="article-title">Our Advantages</div>
                <div class="article-list">
                    <div class="article-li" v-for="(item,index) in advantagesList" :key="index">
                        <div class="article-list-img-box">
                            <div class="article-list-img1"><img :src="item.img"></div>
                        </div>
                        <div class="article-list-name">{{item.name}}</div>
                        <div class="article-list-text">{{item.text}}</div>
                    </div>
                </div>
            </div>
            <div class="article article3">
                <div class="article-title">Our Team</div>
                <div class="article-list">
                    <div class="article-li" v-for="(item,index) in teamList" :key="index">
                        <div class="article-list-img1"><img :src="item.img"></div>
                        <div class="article-list-name1">{{item.name1}}</div>
                        <div class="article-list-name2">{{item.name2}}</div>
                        <div class="article-list-text">{{item.text}}</div>
                    </div>
                </div>
            </div>
            <footer>
                <div class="footerHeader-box">
                    <div class="footer-left">
                        <div class="footer-input-title">Subscribe to rabbit hole.</div>
                        <div class="footer-input-title-second">The World's First NFT Platform Dedicated To Minting Great Moments In History.</div>
                        <div class="footer-input">
                            <div><input type="email" v-model="subscribeEmail" placeholder="Your email address"></div>
                            <div class="footer-input-button" @click="subscribe">Subscribe</div>
                        </div>
                    </div>
                    <div class="footer-right">
                        <div class="footer-left-title">Join the community</div>
                        <div class="footer-left-list">
                            <a target="_blank" href="https://twitter.com/History_DAO"><img src="../../assets/images/homeIcon/logoIcon1.png"></a>
                            <a target="_blank" href="https://medium.com/@HistoryDAO"><img src="../../assets/images/homeIcon/logoIcon2.png"></a>
                            <a target="_blank" href="https://discord.com/invite/BztebRcpyY"><img src="../../assets/images/homeIcon/logoIcon3.png"></a>
                            <a target="_blank" href="https://www.youtube.com/c/HistoryDAO"><img src="../../assets/images/homeIcon/logoIcon4.png"></a>
                            <!-- <a target="_blank" href=""><img src="../../assets/images/homeIcon/logoIcon5.png"></a> -->
                            <a target="_blank" href="mailto:support@historydao.io"><img src="../../assets/images/homeIcon/logoIcon6.png"></a>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="footer-bottom-left">@HistoryDAO</div>
                    <div class="footer-bottom-right">
                        <a :href="`${publicPath}file/HistoryDAO WhitePaperFA.pdf`" target="_blank">Whitepaper</a>
                        <a :href="`${publicPath}file/HistoryDAO Smart Contract Audit Report.pdf`" target="_blank">Audit Report</a>
                        <a href="https://medium.com/@historyofficial11/history-community-guidelines-319765448bb8" target="_blank">Community Guidelines</a>
                        <a href="https://medium.com/@historyofficial11/history-privacy-policy-8626e65cd212" target="_blank">Privacy Policy</a>
                        <a href="https://medium.com/@historyofficial11/history-terms-of-service-33ae25ff1948" target="_blank">Term of Service</a>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>
<script>
import { chainName, chainImg, networkName } from "../../web3/web3Config";
const address = require('../../web3/web3Config.js');
import { createHistoryV1ERC721 } from "../../web3/HistoryV1ERC721";
import { queryHistoryMarket } from '../../web3/HistoryMarket';
import { getChainIdNetwork } from "../../web3/common/wallet";
import * as swiperAni from '../../common/swiperAni.js'
// import { ethers} from 'ethers';
export default {
    name: "homePage",
    inject: ["reload"],
    data() {
        return {
            chainImg: chainImg,
            publicPath: process.env.BASE_URL,
            rellax: {
                speed: -4,
            },
            rellax1: {
                speed: 1
            },
            rellax2: {
                speed: -2
            },
            baseUrl: 'https://api.blockhistory.io/history/uploadfile',
            baseUrlData: '',
            historyListQuery: {
                pagenum: 1,
                pagesize: 3,
                network: ''
            },
            historyListQuery2: {
                pagenum: 1,
                pagesize: 3,
            },
            allHistoryList: [],
            seriesList1: [],
            total: '',
            seriesAdmin: '',
            introduceList: [
                { images: require('../../assets/images/home-title1.png'), line1: "World's First Platform", line2: 'for Minting History NFTs', line3: 'An innovative value proposition for NFT collectors, history enthusiasts, and fans of emerging digital technologies' },
                { images: require('../../assets/images/home-title2.png'), line1: 'Fully Decentralised, Scalable', line2: 'and Diverse Ecosystem', line3: 'Cutting-edge ecosystem functionalities including minting, following, staking, trading memorable historical events, and online community interactions' },
                { images: require('../../assets/images/home-title3.png'), line1: 'Customer-friendly,', line2: 'Low Barrier to Entry', line3: 'Reduced gas fees by storing data in the Hash chain, easily accessible and inclusive of all participants' },
            ],
            articleList: [
                { img: require('../../assets/images/firstHistory-icon1.png'), name: 'Set up your wallet', text: 'Set up your wallet of choice, connect it to History by clicking the wallet icon in the top right corner.' },
                { img: require('../../assets/images/firstHistory-icon2.png'), name: 'Create your History', text: 'Add social links, a description, profile & banner images, and set a secondary sales fee,and upload your work , add a title and description, and customize your NFTs with properties, stats.' },
                { img: require('../../assets/images/firstHistory-icon3.png'), name: 'Sell them', text: 'You choose how you want to sell your NFTs, and we help you sell them!' }
            ],
            advantagesList: [
                { img: require('../../assets/images/tamperproofImg.png'), name: 'Tamperproof', text: 'By leveraging the security of the Ethereum network, once the event is stored by the ‘Mint’ function, it is permanently secured and cannot be tampered with.' },
                { img: require('../../assets/images/advantages-icon2.png'), name: 'Support and Media Enrichment', text: 'The technology can easily expand to enrich text, pictures, videos, and other data formats to increase the amount of information reported.' },
                { img: require('../../assets/images/advantages-icon3.png'), name: 'Reduced Fees', text: 'Reduces gas fees by storing data in the Hash chain, enabling more user activity' },
            ],
            teamList: [
                { img: require('../../assets/images/team1.png'), name1: 'Skyler C Harris ', name2: 'CEO', text: `From the United States. Graduated from University of Utah with Bachelor's Degree Major in English, Minor in History. Overall management of company and content governance committee. Over 10 years of experience in content production and market strategy. Former ByteDance executive with extensive industry network across North America.` },
                { img: require('../../assets/images/team2.png'), name1: 'Sascha Stange', name2: 'Marketing & Operations Director', text: `Form Germany. Marketing and communication, Community management and product experience design. Urban design and tourism experience creator. Experience economy and blockchain enthusiast with proven track record in consumer relations, communication, and product design.` }, { img: require('../../assets/images/team3.png'), name1: 'Russ Fischer', name2: 'Art Director', text: `From Russia. Design. Advertising major. Responsible for artistic design. Renowned International Designer with over 10 internationally recognized accolades. Former Head of Design at 4A advertising agencies; managed projects include Olympic Games, DreamWorks, Samsung,   Calvin Klien, Armani, P&G, Nestle and more.` },
            ],
            seriesList: [],
            subscribeEmail: '',
            chainName: chainName,
            HistoryERC721: '',
            HistorySeriesERC721: '',
            isHeight: '',
            isHeight1: '',
            edit: '',
            networkIsShow: '',
            swiperList: [
                { img: require('../../assets/images/homePageimg1.png') },
                { img: require('../../assets/images/homePageimg2.png') },
                { img: require('../../assets/images/homePageimg3.png') },
                { img: require('../../assets/images/homePageimg4.png') },
                { img: require('../../assets/images/homePageimg5.png') },
                { img: require('../../assets/images/homePageimg6.png') },
            ],
            swiperOption: {
                navigation: {
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                },
                autoplay: {
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
                loop: true,
                effect: 'fade',
                on: {
                    init: function() {
                        swiperAni.swiperAnimateCache(this)
                        swiperAni.swiperAnimate(this)
                    },
                    slideChange: function() {
                        swiperAni.swiperAnimate(this)
                    }
                }
            },
        }
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },
        chainId() {
            return this.$store.getters.walletInfo.chainId;
        },
        swiper() {
            return this.$refs.mySwiper.swiper
        }
    },
    watch: {
        '$store.getters.initStatusComplete': {
            immediate: true,
            handler(flag) {
                if (flag) {
                    this.init();
                }
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("scroll", this.handleScroll, true);
        });
    },
    methods: {
        async init() {
            this.baseUrlData = localStorage.getItem('baseUrl')

            let res = '';
            if (this.mapState.chainId) {
                res = this.mapState.chainId
            } else {
                res = await getChainIdNetwork();
            }
            this.networkIsShow = networkName[res]
            this.HistoryERC721 = address.address[this.chainName[res]].HistoryERC721;
            this.HistorySeriesERC721 = address.address[this.chainName[res]].HistorySeriesERC721;
            this.queryAllHistory();
            this.queryAllHistory1()
            this.queryGetseriesinfos()
        },
        handleScroll() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop >= 130) {
                this.isHeight = true
            } else {
                this.isHeight = false
            }
            if (scrollTop >= 200) {
                this.isHeight1 = true
            } else {
                this.isHeight1 = false
            }
        },
        goView(view) {
            this.$router.push({
                path: `/${view}`
            })
        },
        goMarket(id, admin, tokenaddress) {
            let stateAdmin = this.mapState.address.toLowerCase()
            let tokenAddress = tokenaddress
            if (admin == stateAdmin) {
                this.edit = true;
                let isShow = true;
                this.$router.push({ path: "/series", query: { id, isShow, edit: this.edit, tokenAddress } });
            } else {
                let isShow = false;
                this.edit = false;
                this.$router.push({ path: "/series", query: { id, isShow, edit: this.edit } });
            }
        },
        goTransaction(item, view) {
            this.itemData = {
                tokenid: item.tokenid,
                price: 1,
                currentPage: 0,
            }
            let transactionState = view
            let historyItem = item
            let tokenaddress = item.tokenaddress
            this.$router.push({ path: '/transaction', query: { itemData: JSON.stringify(this.itemData), item: JSON.stringify(historyItem), transactionState: transactionState, tokenaddress: tokenaddress } })
        },
        cancelSale1(item) {
            let _this = this;
            let erc21 = queryHistoryMarket();
            erc21.sellNft(this.HistoryERC721, item.tokenid, 0).then(() => {
                let params = {
                    admin: this.mapState.address.toLowerCase(),
                    tokenid: item.tokenid
                };
                this.$axios.post(this.baseUrlData + this.$api.post_cancelSale, params).then(() => {
                    this.$message({
                        message: 'Operation succeeded',
                        type: 'success'
                    });
                    _this.reload()
                })
            })
        },
        cancelSaleNew(item) {
            let _this = this;
            let erc21 = queryHistoryMarket();
            erc21.sellNft(this.HistorySeriesERC721, item.tokenid, 0).then(() => {
                let params = {
                    admin: this.mapState.address.toLowerCase(),
                    tokenid: item.tokenid
                };
                this.$axios.post(this.baseUrlData + this.$api.post_cancelseriessale, params).then(() => {
                    this.$message({
                        message: 'Operation succeeded',
                        type: 'success'
                    });
                    _this.reload()
                })
            })
        },
        queryAllHistory() {
            let _this = this;
            this.historyListQuery.network = this.mapState.network ? this.mapState.network : this.networkIsShow;
            let params = this.historyListQuery;
            this.$axios.get(this.baseUrlData + this.$api.post_allHistory, params).then(res => { //, params
                if (res) {
                    if (!params.network) {
                        return;
                    }
                    _this.allHistoryList = res.result.list;
                    _this.total = res.result.total;
                    _this.allHistoryList.forEach((item, index) => {
                        if (item.admin == this.mapState.address.toLowerCase()) {
                            _this.allHistoryList[index].authentication = true;
                        } else {
                            _this.allHistoryList[index].authentication = false;
                        }
                        //Initialize forbidden state
                        _this.allHistoryList[index].buyState = false;
                        _this.allHistoryList[index].followState = false;
                        //query state buy and follow
                        _this.isBuyFollow(item, index);
                    })
                }
            })
        },
        queryAllHistory1() {
            let _this = this;
            let params = {
                pagenum: this.historyListQuery2.pagenum,
                pagesize: this.historyListQuery2.pagesize,
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                seriesid: '',
                admin: '',
                tokenaddress: '',
            }
            this.$axios.get(this.baseUrlData + this.$api.post_getseriesnfts, params).then(res => {
                if (!params.network) {
                    return;
                }
                this.seriesList1 = this.seriesList1.concat(res.result.list);
                this.seriesList1.forEach((item, index) => {
                    if (item.admin == this.mapState.address.toLowerCase()) {
                        _this.seriesList1[index].authentication = true;
                    } else {
                        _this.seriesList1[index].authentication = false;
                    }
                    let address = item.admin;
                    let startText = address.substring(0, 5);
                    let endText = address.substring(address.length - 4, address.length);
                    this.seriesAdmin = startText + '...' + endText;
                    _this.seriesList1[index].buyState = false;
                    _this.seriesList1[index].followState = false;
                    _this.isBuyFollow1(item, index);
                })
            })
        },
        isBuyFollow(item, index) {
            let _this = this;
            let ctr = queryHistoryMarket();
            let erc721 = createHistoryV1ERC721();
            //is buy
            ctr.tokenSaleInfo(this.HistoryERC721, item.tokenid).then(buyState => {
                if (buyState.onSale === false) {
                    _this.allHistoryList[index].buyState = false;
                } else {
                    _this.allHistoryList[index].buyState = true;
                }
                this.$forceUpdate();
                //is follow
                erc721.tokenEventId(item.tokenid).then(eventId => {
                    //follow time
                    erc721.getConfig().then(mintStartTime => {
                        erc721.eventData(eventId).then(firstMintTime => {
                            let startTime = Number(mintStartTime._mintStartTime) + Number(firstMintTime.firstMintTime)
                            let endTime = Number(mintStartTime._mintEndTime) + Number(firstMintTime.firstMintTime)
                            if (Date.parse(new Date()).toString().substr(0, 10) >= startTime && Date.parse(new Date()).toString().substr(0, 10) <= endTime) {
                                _this.allHistoryList[index].followState = true;
                            } else {
                                _this.allHistoryList[index].followState = false;
                            }
                            this.$forceUpdate();
                        })
                    })
                })
            })
        },
        isBuyFollow1(item, index) {
            let _this = this;
            let ctr = queryHistoryMarket();
            let erc721 = createHistoryV1ERC721();
            //is buy
            ctr.tokenSaleInfo(this.HistorySeriesERC721 == item.tokenaddress ? this.HistorySeriesERC721 == item.tokenaddress : item.tokenaddress, item.tokenid).then(buyState => {
                if (buyState.onSale === false) {
                    _this.seriesList1[index].buyState = false;
                } else {
                    _this.seriesList1[index].buyState = true;
                }
                this.$forceUpdate();
                //is follow
                erc721.tokenEventId(item.tokenid).then(eventId => {
                    //follow time
                    erc721.getConfig().then(mintStartTime => {
                        erc721.eventData(eventId).then(firstMintTime => {
                            let startTime = Number(mintStartTime._mintStartTime) + Number(firstMintTime.firstMintTime)
                            let endTime = Number(mintStartTime._mintEndTime) + Number(firstMintTime.firstMintTime)
                            if (Date.parse(new Date()).toString().substr(0, 10) >= startTime && Date.parse(new Date()).toString().substr(0, 10) <= endTime) {
                                _this.seriesList1[index].followState = true;
                            } else {
                                _this.seriesList1[index].followState = false;
                            }
                            this.$forceUpdate();
                        })
                    })
                })
            })
        },
        //go market
        moreHistory(key) {
            this.$router.push({
                path: '/market',
                query: { tab: key }
            })
        },
        follow(item) {
            if (item.followState === true) {
                let ctr = createHistoryV1ERC721();
                ctr.tokenEventId(item.tokenid).then(eventId => {
                    //follow time
                    ctr.getConfig().then(mintStartTime => {
                        ctr.eventData(eventId).then(firstMintTime => {
                            let startTime = Number(mintStartTime._mintStartTime) + Number(firstMintTime.firstMintTime)
                            let endTime = Number(mintStartTime._mintEndTime) + Number(firstMintTime.firstMintTime)
                            if (Date.parse(new Date()).toString().substr(0, 10) >= startTime && Date.parse(new Date()).toString().substr(0, 10) <= endTime) {
                                ctr.followHistoryEvent(eventId).then((tokenid) => {
                                    let params = {
                                        admin: this.mapState.address.toLowerCase() ? this.mapState.address.toLowerCase() : '',
                                        eventid: Number(eventId),
                                        tokenid: String(tokenid)
                                    };
                                    this.$axios.post(this.baseUrlData + this.$api.post_followtoken, params).then(() => {
                                        this.$message({
                                            message: 'Operation succeeded',
                                            type: 'success'
                                        });
                                        console.log("success: follow")
                                    })
                                })
                            } else {
                                this.$message({
                                    message: 'Allowed time exceeded',
                                    type: 'warning'
                                });
                            }
                        })
                    })
                })
            }
        },
        bugNow(item) {
            if (item.buyState === true) {
                let ctr = queryHistoryMarket();
                ctr.tokenSaleInfo(this.HistoryERC721, item.tokenid).then(buyState => {
                    if (buyState.onSale === false) {
                        this.$message({
                            message: 'Not available for purchase',
                            type: 'warning'
                        });
                    } else {
                        ctr.buyNft(this.HistoryERC721, item.tokenid, this.mapState.address, buyState.price).then(() => {
                            let params = {
                                admin: this.mapState.address.toLowerCase() ? this.mapState.address.toLowerCase() : '',
                                tokenowner: item.admin.toLowerCase(),
                                tokenid: item.tokenid,
                            };
                            this.$axios.post(this.baseUrlData + this.$api.post_buytoken, params).then(() => {
                                this.$message({
                                    message: 'Operation succeeded',
                                    type: 'success'
                                });
                                console.log("success: bugNow")
                            })
                        })
                    }
                })
            }
        },
        bugNow1(item) {
            if (item.buyState === true) {
                let ctr = queryHistoryMarket();
                ctr.tokenSaleInfo(this.HistorySeriesERC721 == item.tokenaddress ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid).then(buyState => {
                    if (buyState.onSale === false) {
                        this.$message({
                            message: 'Not available for purchase',
                            type: 'warning'
                        });
                    } else {
                        ctr.buyNft(this.HistorySeriesERC721 == item.tokenaddress ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid, this.mapState.address, buyState.price).then((res) => {
                            let params = {
                                admin: this.mapState.address.toLowerCase(),
                                tokenowner: item.admin.toLowerCase(),
                                tokenid: item.tokenid,
                                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                                tokenaddress: item.tokenaddress
                            };
                            this.$axios.post(this.baseUrlData + this.$api.post_buyseriestoken, params).then(() => {
                                this.$message({
                                    message: 'Operation succeeded',
                                    type: 'success'
                                });
                                this.reload()
                                console.log("success: bugNow")
                            })
                        })
                    }
                })
            }
        },
        goHistory(item) {
            if (item.admin == this.mapState.address.toLowerCase()) {
                this.$router.push({
                    path: `/history/?tokenId=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false`,
                });
            } else {
                this.$router.push({
                    path: `/history/?tokenId=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=true`,
                });
            }
        },
        goHistory1(item) {
            if (item.admin == this.mapState.address.toLowerCase()) {
                this.$router.push({
                    path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false`,
                });
            } else {
                this.$router.push({
                    path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=true`,
                });
            }
        },
        //subscribe
        subscribe() {
            let params = {
                admin: this.mapState.address.toLowerCase() ? this.mapState.address.toLowerCase() : '',
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                email: this.subscribeEmail,
            };
            this.$axios.post(this.baseUrlData + this.$api.post_subscribe, params).then(res => {
                if (res) {
                    this.$message({
                        message: 'Success',
                        type: 'success'
                    });
                    this.subscribeEmail = '';
                }
            }).catch(() => {
                this.$message({
                    message: 'fail',
                    type: 'warning'
                });
            })
        },
        queryGetseriesinfos() {
            let params = {
                pagenum: 1,
                pagesize: 3,
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
            }
            this.$axios.get(this.baseUrlData + this.$api.post_getseriesinfos, params).then(res => {
                if (!params.network) {
                    return;
                }
                this.seriesList = res.result.list
                this.seriesList.forEach((item, index) => {
                    let address = item.admin;
                    let startText = address.substring(0, 5);
                    let endText = address.substring(address.length - 4, address.length);
                    this.seriesAdmin = startText + '...' + endText;
                })
            }).catch(() => {

            })
        },
    },
    beforeDestory() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>
<style lang="scss" src="../../assets/css/homePage.scss" scoped></style>
<style lang="scss" src="../../assets/css/homePageMobile.scss" scoped></style>